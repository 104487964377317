<template>
    <div class="successful-rate">
        <breadcrumbs
            :settings="settingsBreadcrumbs"
            back-btn-path="/"
        />
        <div
            class="successful-rate__container"
        >
            <successful-payment-icon />
            <p class="successful-rate__text">
                In these days, client reviews are incredibly important for us.
            </p>
            <div class="successful-rate__money-block">
                <p class="successful-rate__title">
                    <span>You will get US $10, if you rate your experience on the following platforms:</span>
                </p>
                <ul class="successful-rate__buttons">
                    <li>
                        <a
                            href="https://www.sitejabber.com/online-business-review?url=customessaymeister.com"
                            target="_blank"
                            class="btn-base btn-main"
                        >Review us on SiteJabber</a>
                    </li>
                    <!-- <li>
                        <a
                            href="https://www.sitejabber.com/online-business-review?url=customessaymeister.com"
                            target="_blank"
                            class="btn-base btn-main"
                        >Review us on SiteJabber</a>
                    </li> -->
                </ul>
            </div>
            <p class="successful-rate__text">
                After you are done, notify our customer service to claim the bonus
            </p>
            <ul class="successful-rate__buttons">
                <li>
                    <custom-button
                        default
                        class="button btn-base_colored"
                        :type="'submit'"
                        @on-btn-click="$router.push({ name: 'support_form' })"
                    >
                        Contact Support
                    </custom-button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import SuccessfulPaymentIcon from '@/components/icons/SuccessfulPaymentIcon.vue';
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
    name: 'SuccessfulRateWriter',
    components: {
        SuccessfulPaymentIcon,
        Breadcrumbs
    },
    metaInfo: {
        title: 'Thank You for Your Feedback and Rating!'
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                additionalClass: breadcrumbs.additionalClass
            }
        }
    }
}
</script>

<style lang="scss">
.successful-rate {
    width: 100%;
    min-height: 700px;
    min-width: 375px;
    &__container {
        padding: 40px ;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fafafa;
        border-radius: 10px;
        @media screen and (max-width: 576px) {
            padding: 20px;
            margin-bottom: 20px;
        }
        svg {
            margin-left: -28px;
            margin-bottom: 20px;
        }
    }
    &__title {
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 1.4;
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 576px) {
            font-size: 28px;
            margin-top: 25px;
            margin-bottom: 10px;
        }
    }
    &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
        @media screen and (max-width: 576px) {
            font-size: 20px;
            line-height: 1.4;
        }
    }
    &__buttons {
        margin-top: 40px;
        display: flex;
        gap: 20px;
    }

    & .button:first-child {
        margin-right: 20px;
    }

    &__money-block {
        background: #eaeaea;
        border-radius: 20px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}
</style>
